<script setup lang="ts">
import RatingStars from "~/components/custom-ui/RatingStars.vue";
import type {PortfolioType} from "~/types/Portfolio";

const {t} = useI18n()
const globalStore = useGlobalStore()
const props = defineProps({
    portfolio: {
        type: Object as PropType<PortfolioType>,
        required: true,
    }
});

/*const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric'};
    return date.toLocaleDateString('en-US', options);
};*/

//const formattedDate = computed(() => formatDate(specialist.last_review?.created_at))

const childSpecifications = computed(() => globalStore.specifications.filter(specification => {
    return props.portfolio.specifications.some(item => item.documentId === specification.documentId)
}))

</script>

<template>
    <div class="specialist specialist_card h-full p-6 flex flex-col justify-between bg-stroke">
        <div class="specialist_card__top">
            <NuxtLink :to="{name: 'portfolio', params: {id: portfolio.documentId}}" class="specialist__information">
                <CustomUiUserAvatar :src="portfolio.user?.avatar?.formats?.small?.url" :name="portfolio.user?.username" size="sm" />
                <div class="specialist__main flex-flex-col justify-center">
                    <p class="text-main font-medium mb-2">{{ portfolio.user?.username }}</p>
                    <div class="specialist__meta">
                        <div v-if="portfolio.user.compute_rating" class="specialist__rating flex items-center">
                            <span>{{ $t('User rating:') }}</span>
                            <Icon name="ri:star-fill" size="14px" class="text-accent mt-0.5 mx-1" />
                            <span>{{ portfolio.user.compute_rating?.toFixed(1) }}</span>
                        </div>
<!--                        <div v-if="specialist.completed_tasks_count" class="specialist__counter">{{t('Completed {count} tasks', {count: specialist.completed_tasks_count})}}</div>-->
                    </div>
                </div>
            </NuxtLink>

            <div class="specialist__tags mt-4">
                <NuxtLink :to="{name: 'master_category', params: {slug: item.slug}}" v-for="item in childSpecifications"
                    class="tags__item">{{ item.name }}
                </NuxtLink>
            </div>

            <div class="specialist__experience mt-4">{{ portfolio?.description }}</div>
        </div>

<!--        <div class="specialist_card__bottom mt-4">
            <div v-if="specialist.last_review" class="specialist__feedback">
                <div class="grid" v-if="specialist.last_review?.customer">
                    <span class="client_name">{{ specialist.last_review?.customer.name }}</span>
&lt;!&ndash;                    <span class="category">Registration, liquidation of companies</span>&ndash;&gt;
                </div>
                <div class="description">
                    {{ specialist.last_review?.comment }}
                </div>
                <div class="footer">
                    <RatingStars :rating="specialist.last_review?.rating || 5" />
                    <span class="feedback__date">{{ formattedDate }}</span>
                </div>
            </div>
        </div>-->
    </div>
</template>
<style scoped lang="scss">
.specialist {
    border-radius: 36px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: relative;

    &__information {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    &__rating {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
        color: #525866;
    }

    &__counter {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.015em;
        font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
        color: #525866;
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .tags__item {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 8px;
            border: 1px solid #2D9F75;
            border-radius: 999px;
            flex: none;
            order: 0;
            flex-grow: 0;
            background: none;
            font-family: 'Inter', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #2D9F75;
        }
    }

    &__experience {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        color: #525866;
    }

    &__feedback {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
        gap: 8px;
        background: #FFFFFF;
        border: 1px solid #E2E4E9;
        border-radius: 12px;

        .client_name {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            color: #0A0D14;
        }

        .category {
            font-size: 10px;
            font-weight: 400;
            line-height: 12.1px;
            letter-spacing: -0.006em;
            text-align: left;
            color: #525866;
        }

        .description {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-family: 'Inter', serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            letter-spacing: -0.006em;
            text-align: left;
            color: #0A0D14;
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    &__highlight {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 53px;
        height: 20px;
        border-radius: 10px;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 2px 2px 8px;
        gap: 2px;
        background: #FFDAC2;
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
        color: #6E330C;
        flex: none;
        order: 0;
        flex-grow: 0;

    }

    .feedback__date{
        font-size: 10px;
    }
}
</style>